(function () {
  'use strict';

  /* eslint-disable no-param-reassign */

  /**
   * Returns the metric number to a fixed precision (ensuring it remains a number).
   *
   * @param {number} metric - the metric to adjust
   * @returns {number} the metric fixed to 2 decimal points.
   */
  function getFixedNumber(metric) {
    return Number(metric.toFixed(2));
  }

  /**
   * Populates the first-paint timing metric in the pData object, provided the Paint Timing API is available.
   *
   * @param {object} pData - The performance data object to populate.
   */
  function populateFirstPaintTiming(pData) {
    // Metric is non-standard, and is mainly just in Chrome.
    if (window.PerformancePaintTiming) {
      const paintMetrics = performance.getEntriesByType('paint');

      if (Array.isArray(paintMetrics) && paintMetrics.length > 0) {
        pData.firstPaint = getFixedNumber(paintMetrics[0].startTime);
      }
    }
  }

  /**
   * Populates performance timing data from the legacy PerformaceTiming API, which is now deprecated. This should only be
   * used if the PerformanceNavigationTiming API is not available.
   *
   * @param {object} pData - The performance data object to populate.
   */
  function populateLegacyTimingMetrics(pData) {
    const { timing } = performance;

    // Make sure the API hasn't been dropped yet before accessing metrics.
    if (typeof timing === 'object') {
      const startTime = timing.navigationStart;

      pData.dnsTime = getFixedNumber(timing.domainLookupEnd - timing.domainLookupStart);
      pData.connectionTime = getFixedNumber(timing.connectEnd - timing.connectStart);
      pData.timeToFirstByte = getFixedNumber(timing.responseStart - timing.requestStart);
      pData.domComplete = getFixedNumber(timing.domComplete - startTime);
      pData.domContentLoaded = getFixedNumber(timing.domContentLoadedEventStart - startTime);
      pData.documentLoad = getFixedNumber(timing.loadEventStart - startTime);
      pData.domInteractive = getFixedNumber(timing.domInteractive - startTime);
    }
  }

  /**
   * Populates performance timing data from the PerformanceNavigationTiming API.
   *
   * @param {object} pData - The performance data object to populate.
   */
  function populateNavigationTimingMetrics(pData) {
    const navigationPerf = performance.getEntriesByType('navigation')[0];

    pData.dnsTime = getFixedNumber(navigationPerf.domainLookupEnd - navigationPerf.domainLookupStart);
    pData.connectionTime = getFixedNumber(navigationPerf.connectEnd - navigationPerf.connectStart);
    pData.timeToFirstByte = getFixedNumber(navigationPerf.responseStart - navigationPerf.requestStart);
    pData.domComplete = getFixedNumber(navigationPerf.domComplete);
    pData.domContentLoaded = getFixedNumber(navigationPerf.domContentLoadedEventStart);
    pData.documentLoad = getFixedNumber(navigationPerf.loadEventStart);
    pData.domInteractive = getFixedNumber(navigationPerf.domInteractive);

    // Metric is not be available in some browsers (i.e. Safari)
    if (navigationPerf.encodedBodySize) {
      pData.bodySize = navigationPerf.encodedBodySize;
    }
  }

  /**
   * Retrieve performance timing metrics, from the current or legacy APIs.
   *
   * @returns {object|null} returns performance data if available, otherwise null.
   */
  function getTimingMetrics() {
    if (window.performance) {
      const pData = {};

      pData.numOfScript = 0;
      pData.numOfCss = 0;
      pData.numOfImg = 0;
      pData.sizeOfScripts = 0;
      pData.sizeOfCss = 0;

      performance.getEntries().forEach((e) => {
        if (/.*css$/.test(e.name) && e.encodedBodySize > 0) {
          pData.numOfCss += 1;
          pData.sizeOfCss += e.encodedBodySize;
        } else if (e.initiatorType === 'script' && e.encodedBodySize > 0) {
          pData.numOfScript += 1;
          pData.sizeOfScripts += e.encodedBodySize;
        } else if (e.initiatorType === 'img') {
          pData.numOfImg += 1;
        }
      });

      // Use the modern timing API, if available
      if (window.PerformanceNavigationTiming) {
        populateNavigationTimingMetrics(pData);
      } else {
        // Otherwise, default to the legacy one
        populateLegacyTimingMetrics(pData);
      }

      populateFirstPaintTiming(pData);

      return pData;
    }

    return null;
  }

  /**
   * Measure Performance API timing data. Uses the "submit" callback to submit the measured data to.
   *
   * @param {function} submitHandler - callback to submit the performance data to.
   * @param {function} errorHandler - callback to pass errors to.
   * @param {object} options - options object.
   * @param {number} options.delay - imposed delay before deriving metrics from the Performance API. Defaults to 200ms.
   *
   * @returns {function} callback that will remove the load event listener when invoked.
   */
  function measureTimingData(submitHandler, errorHandler, options = errorHandler || {}) {
    if (!submitHandler || typeof submitHandler !== 'function') {
      throw new Error('submitHandler is required and must be a function');
    }

    const { delay = 200 } = options;

    function onLoad() {
      setTimeout(() => {
        try {
          const pData = getTimingMetrics();

          if (pData) {
            submitHandler(pData);
          }
        } catch (error) {
          if (errorHandler && typeof errorHandler === 'function') {
            errorHandler(error);
          }
        }
      }, delay);
    }

    window.addEventListener('load', onLoad);

    return function removeListener() {
      window.removeEventListener('load', onLoad);
    };
  }

  (function($, document, $doc) {
      var eventSessionId, pendingEvents, suSource, ev_data;
      window['optimizely'] = window['optimizely'] || [];
      ebates = ebates || {};
      // --- Cookies; copied from ebates-main.js - some pages do not use it; this makes this file "self-contained" --- //
      ebates.cookie = {
          set: function (name, value, days) {
              var d = new Date(); d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days); document.cookie = name + '=' + value + ';expires=' + d.toUTCString() + ';path=/;';
          },
          get: function (name) {
              var c = document.cookie.split(name + '='); return c.length === 2 ? c.pop().split(';').shift() : false;
          }
      };
      ebates.GA = ebates.GA || {};
      ebates.stats = ebates.stats || {};
      ebates.GA.opts = {
          'cookieDomain': location.hostname,
          'forceSSL': true,
          'useAmpClientId': true,
          'sampleRate': 2
      };
      ebates.GA.id = (location.hostname === 'stage.rakuten.com' || location.hostname === 'preview-www.rakuten.com' || location.hostname === 'www.rakuten.com') ? 'UA-1707619-3' : 'UA-1707619-8';    // TODO move this id resolution logic out to analytics_inline.vm

      ebates.user = ebates.user || {};
      ebates.user.guid && (ebates.GA.opts.userId = ebates.user.guid);
      ebates.user.membership = ebates.user.status && /^REGISTERED/.test(ebates.user.status) ? 'member' : 'non-member';
      /* eslint-disable */
      if (ebates.user.isDataSharingAllowed) {
          (function(i,s,o,g,r,a,m){i.GoogleAnalyticsObject=r;i[r]=i[r]||function(){
              (i[r].q=i[r].q||[]).push(arguments);};i[r].l=+new Date();a=s.createElement(o);
              m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m);
          })(window,document,'script','//www.google-analytics.com/analytics.js','ga');
      } else {
          window.ga = () => {};
      }
      /* eslint-enable */
      ga(function(){
          ebates.GA.ready = true;
      });
      ga('create', ebates.GA.id, ebates.GA.opts);
      ga('require', 'linkid', 'linkid.js');
      ebates.user.status && ga('set', {dimension1: ebates.user.status, dimension2: ebates.user.membership});
      ebates.session && ebates.session.id && ga('set', {dimension3: ebates.session.id});
      ebates.user.guid && ga('set', {dimension9: ebates.user.guid});
      ebates.user.id_last_digit && ga('set', {dimension13: ebates.user.id_last_digit});
      eventSessionId = ebates.cookie.get('ESID');
      eventSessionId && ga('set', {dimension12: eventSessionId});
      ga('send', 'pageview');

      // d.data is expected to be an array of values corresponding to GA analytics.js ga() function parameters
      // Example:
      // GA:  ga('send', 'event', 'Share', 'Referral email sent', 'To 6 recipients', 6 )
      // "stats" event trigger: $(document).trigger("stats", {data: ['send', 'event', 'Share', 'Referral email sent', 'To 6 recipients', 6]});
      ebates.stats.send = function (e, d) {
          var last, callback, callbackTid, sendToGA,
              action = $.noop,
              eventMap = { send: { event: 'y', pageview: 'y' } };

          // dispatch "vanilla" JS event; ignoring obsolete browsers
          typeof window.CustomEvent === 'function' && !function(){
              var vanillaStatsEvent = new CustomEvent('stats-event', { detail: d });

              document.dispatchEvent(vanillaStatsEvent);
          }();

          if (d && $.isArray(d.data) && d.data.length > 1) {
              last = d.data[d.data.length - 1];
              sendToGA = function(){
                  ebates.pathInMap(eventMap, [d.data[0], d.data[1]]) && ga(function(){
                      ga.apply(null, d.data);
                  });
                  !ebates.GA.ready && action();   // If GA not loaded (like blocked by Ad Block) - perform callback immediately
              };
              callback = typeof last === 'object' && typeof last.hitCallback === 'function' ? last.hitCallback : false;
              if (callback) { // example: if the callback is interstitial redirection - tracking is sent sequentially, to ensure data is sent to all services before page redirects
                  callbackTid = setTimeout(callback, ebates.stats.timeout); // if GA fails - execute the callback anyways. Important to bulletproof interstitial redirection
                  action = function(){
                      clearTimeout(callbackTid); callback();
                  };
                  d.data[d.data.length - 1] = {hitCallback: action};
                  $.when.apply($, ebates.stats.webAnalytics(d)).then(sendToGA, sendToGA);
              } else {    // if no callback (in most cases) - tracking is async
                  ebates.stats.webAnalytics(d);
                  sendToGA();
              }
          } else if (d.map) { // No GA events
              ebates.stats.webAnalytics(d);
          }
      };

      // Example of sending "entities" data to Ebates WebAnalytics
      // entities = '[{entityType: "Merchant Session - AdBlock Plus enabled", instanceId: "userId", dynamicEntityType: "Merchant Sessions", dynamicEntry: $!msid}]';
      // $(document).trigger("stats", {data: ["send", "event", "Shopping Trip", action, "redirected", 1], entities: entities});
      ebates.stats.webAnalytics = function (d) {
          var ebEvent, entities, eventType,
              promiseQueue = [],
              defaults,
              webAnalyticsToGaMap = { // TODO - Note: Do not add any more entries to this map, instead use locally:
                  // $(document).trigger("stats", {data: ["send", "event", "Category", "Action", "Label", 1], map: {internal: "Event Type"}, entities: entities});
                  pageview: {
                      '/address-guidedhelp-bigfatpayment-view': 'Address - Guided Help: Big Fat Payment - View',
                      '/address-guidedhelp-bigfatpayment-click': 'Address - Guided Help: Big Fat Payment - Click',
                      '/address-guidedhelp-bigfatpayment-complete': 'Address - Guided Help: Big Fat Payment - Complete'
                  },
                  event: {
                      'Shopping Trip': {'ABP detected': 'Merchant Session - AdBlock Plus enabled'},
                      'Video': {
                          'In-Store Cash Back': 'Video - In-Store Cash Back',
                          'Welcome': {'CBMS': 'Video - Cash Back Made Simple'}
                      },
                      'Overlays': {
                          'Address - Address Overlay - Click': 'Address - Address Overlay - Click',
                          'Address - Address Overlay - Complete': 'Address - Address Overlay - Complete',
                          'Address - Address Overlay - View': 'Address - Address Overlay - View',
                          'How to earn cash back': 'How to Earn Cash Back Overlay'
                      },
                      'Cashstar': {
                          'cashstar-changed-mind': 'Cashstar - Changed Mind',
                          'cashstar-get-card': 'Cashstar - Get Card',
                          'cashstar-sign-in': 'Cashstar - Sign-In'
                      },
                      '/merchant-store-page-visit': 'Merchant Store Page Visit',
                      '/captcha': 'Captcha',
                      '/create-password-failure': 'Create Password - Failure',
                      '/change-password-request-failure': 'Change Password - Request Failure',
                      '/password-force-reset-failure': 'Password Force Reset - Failure',
                      '/change-password-display': 'Change Password - Display',
                      '/change-password-request': 'Change Password - Request',
                      'Pages': {
                          'Welcome': 'View - Ebates Welcome page',
                          'Luxe': {
                              Landing: 'View - Ebates Luxury page',
                              'All Stores': 'View - Ebates Luxury All Stores page'
                          },
                          'HP': {Video: 'View - Ebates test homepage'}
                      }
                  }
              };
          // Optimizely
          if (d.map && d.map.optimizely) {
              if (d.map.optimizely.tags){
                  window['optimizely'].push(d.map.optimizely);
              } else {
                  window.optimizely.push(['trackEvent', d.map.optimizely]);
              }
          }
          // EventDB
          d.data && (eventType = ebates.pathInMap(webAnalyticsToGaMap, d.data.slice(1))); // First see if any internal events are mapped
          eventType = eventType || (d.map && d.map.internal); // then see if a mapping is provided with the event
          if (typeof eventType !== 'undefined' && eventType) {
              entities = d.entities ? d.entities : [ {entityType: eventType, instanceId: 'userId'} ];
              ebEvent = {eventType: eventType, entities: entities};
              promiseQueue.push($.ajax({url: '/frontendEvents.do', data: JSON.stringify(ebEvent), type: 'POST', contentType: 'application/json'}));
          }
          // External Server
          if (d.map && d.map.external && ebates.stats.externalUrl) {
              defaults = {
                  uid: '',
                  guid: ebates.user.guid,
                  sid: ebates.session.id,
                  rsid: ebates.user.rsid,
                  ts: Date.now() - ebates.stats.timeDelta,
                  url: location.href,
                  dt: ebates.stats.device
              };
              eventSessionId && (defaults.esid = eventSessionId);
              ebates.user.status && (defaults.us = ebates.user.status);
              //use send beacon while it available and during unload status,exclude mobile safari since it has bug with send beacon.
              if ( typeof navigator.sendBeacon === 'function' && ebates.stats.unload && !(/(iPad|iPhone|iPod)/.test( navigator.userAgent ) && !/(CriOS|FxiOS)/g.test( navigator.userAgent )) ) {
                  //Use sendBeacon when it is available and events are handled in unload handler, and no callback supplied
                  navigator.sendBeacon(ebates.stats.externalUrl, JSON.stringify($.extend(defaults, d.map.external)));
                  return promiseQueue;
              } else {
                  promiseQueue.push($.ajax({
                      url: ebates.stats.externalUrl,
                      data: JSON.stringify($.extend(defaults, d.map.external)),
                      type: 'POST',
                      contentType: 'text/plain',
                      timeout: ebates.stats.timeout * 2   // on navigation do not stall it for more than 1 sec
                  }));
              }
          }
          return promiseQueue;
      };

      // TODO - move all global events /global-events.es6
      ebates.stats.global = function () {
          // - SignIn/SignUp events
          var $body = $('body'),
              authCookieNames = ['SignIn', 'SignUp'],
              overlayClass = '.tablet-overlay',
              linkTypes = {   // TODO - semantic link type classes would be better (forgot-password, sign-in and sign-up) ...
                  'Forgot Click': '.tablet-overlay .forgotOverlay',
                  'Signin Click': '.tablet-overlay .show-join-overlay.loginIe8',
                  'Signup Click': '.tablet-overlay .show-join-overlay.joinIe8'
              },
              sendLinkEvent = function(e){
                  // eslint-disable-next-line no-invalid-this
                  var type = $(this).closest(overlayClass).find('input[name="type"]');
                  $doc.trigger('stats', {data: ['send', 'event', 'Overlays', e.data.action, type.val && type.val() || 'other']});
              };
          $.each(linkTypes, function(action, link){
              $body.on('click', link, {action: action}, sendLinkEvent);
          });

          // Principal sign up/in event tracking. The event is "surfaced" by BE via ['SignIn', 'SignUp'] cookies carrying the type info with it
          $.each(authCookieNames, function(i, authCookieName){
              const cookieVal = ebates.cookie.get(authCookieName),
                  descriptors = cookieVal && cookieVal.split('|') || [];

              if (descriptors.length > 1) {
                  const isSignUp = authCookieName === authCookieNames[1],
                      authMethod = descriptors[0],
                      authOrigin = descriptors[1].replace(/_/g, ' '),
                      ampAuthEventDataMap = {
                          method: {
                              'form': 'Email',
                              'FB': 'Facebook',
                              'Google': 'Google'
                          }
                      },
                      ampEvent = {
                          eventType: isSignUp ? 'Complete Sign Up' : 'Complete Log In',
                          eventProps: {
                              method: ampAuthEventDataMap.method[authMethod] || authMethod,
                              onboarding_screen_name: ebates.cookie.get('onboard_screen_name') || '',
                              preceding_screen_name: ebates.cookie.get('preceding_screen_name') || '',
                              autofill_email: ebates.cookie.get('si_form_emailAutoFill') === 'true' ? true : false,
                              autofill_password: ebates.cookie.get('si_form_pwdAutoFill') === 'true' ? true : false,
                              browser: ebates.getBrowser().name
                          }
                      };
                  if (ebates.cookie.get('appleAuth')){
                      ampEvent.eventProps.method = 'Apple';
                      ebates.cookie.set('appleAuth', 1, -1);
                  }

                  if (isSignUp) {
                      // This is abracadabra is needed to avoid multiple chaining, like:
                      // ebates.amplitude && ebates.amplitude.userProps && ebates.amplitude.userProps.referred_by ...
                      const {userProps: { referred_by, referrer_share_source } } = ebates.amplitude || { userProps: { referred_by: null, referrer_share_source: null } },
                          signUpProps = { 'has_referrer': !!(ebates.user.registrationType === 'REF' || referred_by) };

                      // if available, add user referred_by prop to the event props
                      if (signUpProps.has_referrer) {
                          referred_by && (signUpProps.referred_by = referred_by);
                          // props.referrer_share_source value is the EEID value;
                          // set in session globally;
                          // exposed as ebates.amplitude.userProps.referrer_share_source in the "SU layer"
                          referrer_share_source && (signUpProps.referrer_share_source = referrer_share_source);
                      }

                      Object.assign(ampEvent.eventProps, signUpProps);

                      ebates.cookie.set('opt-signup', cookieVal); // save a copy of the cookie for Optimizely; session scoped
                      // Send the auth event.
                      // Use jQuery 'stats' event so that it would be possible to subscribe to it, and/or if needed to move this code out into a separate module
                      // TODO - separate core event handling "machinery" from the "global" event set management (now both are mixed in this file)
                      ampEvent.eventProps.autofill_email = null;
                      ampEvent.eventProps.autofill_password = null;
                      $doc.trigger('stats', {
                          data: ['send', 'event', authCookieName, authMethod, authOrigin],
                          map: { amp: ampEvent }
                      });
                  } else {
                      $doc.trigger('stats', {
                          data: ['send', 'event', authCookieName, authMethod, authOrigin],
                          map: { amp: ampEvent }
                      });
                  }


                  // Remove the auth cookie - we are done with it
                  setTimeout(() => ebates.cookie.set(authCookieName, 0, -1), 0);
              }
          });
          document.cookie = 'si_form_emailAutoFill=; expires=Thu, 01 Jan 1970 00:00:00 GMT';
          document.cookie = 'si_form_pwdAutoFill=; expires=Thu, 01 Jan 1970 00:00:00 GMT';
      };

      ebates.stats.addAnonIdParam = function () {
        let segmentAnonId = ebates.cookie.get('ajs_anonymous_id');

        if (segmentAnonId) {
          segmentAnonId = decodeURIComponent(segmentAnonId).replace(/"/g, '');
          const href = this.getAttribute('href');
          this.setAttribute('href', ebates.param.add('anon_id', segmentAnonId, href));
        }
      };

      ebates.stats.trackNavIds = function (e, d) {
          var navId, href, isXfasLink,
              cookieValue = d;
          if (cookieValue) {
              ebates.cookie.set('navigation_id', cookieValue);
          } else if (this.getAttribute) { // if this is a DOM element
              navId = this.getAttribute('data-navigation-id');
              href = this.getAttribute('href');
              isXfasLink = href && href.indexOf('-xfas') !== -1;
              cookieValue = isXfasLink ? (ebates.stats.parentNavId + '|' + navId) : navId;
              ebates.cookie.set('navigation_id', cookieValue);
          }
      };

      ebates.stats.trackClick = {
          queue: {},
          runningEventCount: 0,
          unload: function () {
              var sendEvent = function (eventKey) {
                  ebates.stats.send(null, ebates.stats.trackClick.queue[eventKey]);
              };
              ebates.stats.unload = true;
              $.ajaxSetup({ async: false });  // this is needed because we cross-domain POST events (OPTIONS + POST)
              Object.keys(ebates.stats.trackClick.queue).map(sendEvent);
          },
          send: function(el, data, type){
              var dupePreventionClass = 'double-tracking-off-' + type,
                  event = {
                      key: ++ebates.stats.trackClick.runningEventCount,
                      send: function () {
                          ebates.stats.send(null, data);
                          delete ebates.stats.trackClick.queue[event.key];
                          el.classList.remove(dupePreventionClass);
                      }
                  };
              if (data && !el.classList.contains(dupePreventionClass)) {
                  el.classList.add(dupePreventionClass);
                  ebates.stats.trackClick.queue[event.key] = data;    // this event queue will be processed on "unload"

                  // This needs explanation:
                  // At this code execution point we do not know (and we cannot know) if navigation to a new page is pending.
                  // data-is-nav="no" is designated to indicate that the element click 100% will not result in browser navigation (with same target).
                  // For navigational clicks the delay is needed to ensure the click event is sent on "unload" (else nav stops JS execution and sending event hangs)
                  // For non-nav clicks we do not want any timeout, as that puts Amplitude events in wrong order (ex.: form validation precedes submit event)
                  // By default all click are treated as navigational. Using data-is-nav="no" is optional and needed only in cases when event ordering is important.
                  el.dataset.isNav === 'no' ? event.send() : setTimeout(event.send, ebates.stats.timeout / 2);
              }
          },
          /**
           *  Deprecated pipeline!
           *  Will be removed soon
           *  Please remove any 'data-click-category', 'data-click-action' and 'data-click-label' tagging you may come across in code anywhere
           */
          ga: function () {
              var event = { category: this.getAttribute('data-click-category') };
              event.action = this.getAttribute('data-click-action');
              if (event.action) {
                  event.data = ['send', 'event', event.category, event.action];
                  event.label = this.getAttribute('data-click-label');
                  event.label && event.data.push(event.label);
                  ebates.stats.trackClick.send(this, { data: event.data }, 'ga');
              }
          },
          /**
           * Semi-legacy pipeline
           * Existing events/tagging should be maintained until further notice
           * Consolidation with the Engager pipeline is expected...
           * This type of tagging is encouraged to be removed with Product approval
           * New events should not use this
           * FE codename for this pipeline is "hadoop"
           */
          external: function () {
              var el = this,
                  eventParams = el.getAttribute('data-event-signature').split(','),
                  data = {};

              if (eventParams.length) {
                  eventParams.map(function(attr) {
                      var value = el.getAttribute('data-' + attr);
                      value !== null && (data[attr] = value);
                  });
                  ebates.stats.trackClick.send(this, { map: { external: data } }, 'ext');
              }
          },
          /**
           * Current "engager" pipeline ("engager" endpoint -> Segment -> Amplitude)
           * This tagging implementation suffers from tag bloating,
           * use of "engager" tagging method is preferred
           * WRT tagging, recommendation is to refactor "data-amp-" to use the next method below this one ("engager")
           */
          amplitude: function () {
              const el = this,
                  eventDataSignature = el.getAttribute('data-amp-evt-sig'),
                  eventDataParams = eventDataSignature ? el.getAttribute('data-amp-evt-sig').split(',') : [],
                  props = {},
                  amp = { eventType: el.getAttribute('data-amp-evt-type') };   // the

              if (eventDataParams.length) {
                  eventDataParams.map(function(attr) {
                      switch (attr) {
                      case '_eds':
                          amp.addEdsPropSet = true;
                          break;
                      default: {
                          const value = el.getAttribute('data-amp-' + attr);
                          value !== null && (props[attr] = value);
                      }
                      }
                  });
                  amp.eventProps = props;
              }
              ebates.stats.trackClick.send(this, { map: { amp: amp } }, 'amp');
          },
          /**
           * Current "engager" pipeline ("engager" endpoint -> Segment -> Amplitude)
           * This is the final tagging solution (https://xkcd.com/927/)
           * It addresses tag bloating by:
           *  1. looking up repeated item props in parent
           *  2. slimming down data tag names
           *  3. unifying prop data attrs for click, view and scroll events
           */
          engager: function () {
              const el = this,
                  amp = { eventType: el.dataset.evt },   // data-evt; it is always available here as the event listener is setup for this attribute
                  eventDataSignature = el.dataset.evtSig, // data-evt-sig; optional
                  /**
                   * @description recursively (if needed) travel up the DOM tree to find the prop data attrs
                   * @param el
                   * @param prop
                   * @returns {*|string|null}
                   */
                  getPropValue = (el, prop) => el.dataset[prop] || ((el.parentNode && el.parentNode !== document) ? getPropValue(el.parentNode, prop) : null);

              if (eventDataSignature) {
                  const props = {},
                      eventDataParams = eventDataSignature.split(',');

                  eventDataParams.map(attr => {
                      switch (attr) {
                      case '_eds':
                          amp.addEdsPropSet = true;
                          break;
                      default: {
                          const value = getPropValue(el, attr);

                          value !== null && (props[attr] = value);
                      }
                      }
                  });
                  amp.eventProps = props;
              }

              ebates.stats.trackClick.send(this, { map: { amp: amp } }, 'amp');
          }
      };

      /*
       * Send data error event to loggly and GA.EBCONTENT-22793
       * @param {object} data - The data object including message and label about data error, for definition, refer to JIRA
       */
      ebates.stats.sendDataError = function (data) {
          var ga_label = '';
          if (data && data.message){
              if (data.label){
                  ga_label = typeof data.label === 'object' ? JSON.stringify(data.label) : data.label;
              }
              ga && ga('send', 'event', 'Data Error', data.message, ga_label);
          }
      };


      // Utility function (map - tree-like object with string type "leaves", path - array) returns either "undefined" if no match or the mapped "leaf"
      ebates.pathInMap = function (map, path) {
          return !map || typeof map === 'string' ? map : ebates.pathInMap(map[path.shift()], path);
      };

      // jQuery event triggers supposedly are equivalent to function calls - there should be no "event gap" between the listeners, nor there should be any dupes
      $doc.off('stats');  // remove "deferred" listeners
      $doc.on('stats', ebates.stats.send);  // the main "stats" event listener

      // - JS errors
      window.addEventListener('error', function(e) {
          var charLimit, label, action, stack,
              regEx = {};
          if (e.message && e.filename) {
              charLimit = 300;
              regEx.host = new RegExp(window.location.protocol + '//' + window.location.host, 'gi');
              regEx.file = new RegExp(e.filename, 'gi');
              action = e.message.substring(0, charLimit);
              action += action.length === charLimit ? '...' : '';
              stack = (e.error && e.error.stack) ? e.error.stack.replace(/\s+/gi, ' ').replace(regEx.file, 'FILE').replace(e.message, 'ERR') : '';
              label = (e.filename + ':' + (e.lineno || '') + ':' + (e.colno || '') + '|' + stack).replace(regEx.host, '');
              label += label.length === charLimit ? '...' : '';
              $doc.trigger('stats', {data: ['send', 'event', 'JS', action, label]});
          }

      }, false);
      $(function(){
          ebates.stats.global();

          function createOnEnterEvent(callFcn) {
              return (evt) => {
                  if (evt.key == 'Enter') {
                      callFcn.bind(this)(evt);
                  }
              };
          }

          // Nav ids - needs to be on document in order track links loaded via AJAX
          $doc.on('click', 'a[data-navigation-id]', ebates.stats.trackNavIds)
              .on('set-nav-id', ebates.stats.trackNavIds)
              .on('contextmenu webkitmouseforcewillbegin', 'a[data-navigation-id]', function(){
                  // eslint-disable-next-line no-invalid-this
                  ebates.stats.trackNavIds.call(this);
                  setTimeout($.proxy(ebates.cookie.set, ebates.cookie, 'navigation_id', 0, -1), ebates.stats.timeout * 10);
              })
              .on('click', '[data-click-category]', ebates.stats.trackClick.ga)
              .on('click', '[data-event-signature]', ebates.stats.trackClick.external)
              .on('click', '[data-amp-evt-type]', ebates.stats.trackClick.amplitude)
              .on('keydown', '[data-amp-evt-type]', createOnEnterEvent(ebates.stats.trackClick.amplitude))
              .on('click', '[data-evt]', ebates.stats.trackClick.engager)
              .on('keydown', '[data-evt]', createOnEnterEvent(ebates.stats.trackClick.engager));
          window.addEventListener('unload', ebates.stats.trackClick.unload, false);

          // add anon_id parameter to all Branch links
          if (ebates.branch && ebates.branch.linkPaths.origin) {
            $doc.on('click', `a[href^='${ebates.branch.linkPaths.origin}']`, ebates.stats.addAnonIdParam);
          }
      });

      //Process the pending event from previous page
      try {
          if (window.localStorage.getItem('pending-events')) {
              pendingEvents = JSON.parse(window.localStorage.getItem('pending-events'));
              if (pendingEvents.length > 0) {
                  pendingEvents.forEach(function (e) {
                      setTimeout(function () {
                          ebates.stats.send(null, e);
                      }, 100);
                  });
                  window.localStorage.removeItem('pending-events');
              }
          }

          //Detect social log in from su form
          if (window.localStorage.getItem('social_su_si') && ebates.param.get('social_media')){
              suSource = ebates.optim && ebates.optim.get_custom_su_event && ebates.optim.get_custom_su_event() || 'tracking-error',
              ev_data = {data: [],
                  map: { external: {ec: 'Signup Form', ea: 'Sign In Success', el: suSource },
                      optimizely: { 'type': 'event',
                          'eventName': 'Signup Form',
                          'tags': {
                              'event_category': 'Signup Form',
                              'event_action': 'Sign In Success',
                              'event_label': suSource
                          }}}};
              setTimeout(function () {
                  ebates.stats.send(null, ev_data);
              }, 100);
          }
      } catch (e){}

      if (ebates.user && ebates.user.loggedIn){
          if (!ebates.cookie.get('_mall_uuid_cp') || !ebates.cookie.get('grm_cp')){
              $.get('/ajax/rat_cookie.htm');
          }
      } else {
          if (!ebates.cookie.get('_mall_uuid_cp')){
              $.get('/ajax/rat_cookie.htm');
          }
      }

      measureTimingData(function submit(pData) {
          if (ebates.page && ['home', 'allstores', 'store'].indexOf(ebates.page.type) >= 0 ) {
              pData.browser = ebates.getBrowser().name;
              $(document).trigger('stats', {
                  data: [],
                  map: { external: { ec: 'Page performance', et: 'Navigation', ea: ebates.page.type, el: pData } }
              });
          }
      });
  })($, document, $(document));

})();
